import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ChartsModule } from 'ng2-charts';
import { TagInputModule } from 'ngx-chips';
import { MomentModule } from 'angular2-moment';
import { AccountsFormComponent } from 'components/accounts-form';
import { ToastComponent, ToastItemComponent } from 'components/toast';
import { TooltipComponent, TooltipDirective } from 'components/tooltip';
import { UserInfoComponent } from 'components/user-info';
import { ModalDateRangeComponent } from 'components/modal-date-range';
import { DatePickerComponent } from 'components/date-picker';
import { CommonModule } from 'common';
import { FormLabelComponent } from 'components/form-label';
import { CampaignsListComponent } from 'components/campaigns-list';
import { TagModule } from 'components/tag';
import { DetailFieldAreaComponent } from 'components/detail-field-area';
import { FoldableListComponent } from 'components/foldable-list';
import { PlayTimesInputModule } from './playtimes-input';
import { DetailFieldComponent } from './detail-field';
import { CompanyFieldComponent } from './company-field';
import { ChartComponent } from './chart';
import { FileUploadComponent } from './file-upload';
import { BarChartComponent } from './bar-chart';
import { OrderPaymentsComponent } from './order-payments';
import { LoaderComponent, LoaderManager, LoaderMinimalComponent } from './loader';
import { ModalConfirmComponent } from './modal-confirm';
import { ModalIntegrationText } from './modal-integration-text';
import { ModalCheckPixelComponent } from './modal-check-pixel';
import { AudioComponent } from './audio';
import { BackgroundComponent } from './background';
import { AutopromoteAdsListComponent } from './autopromote-ads-list';
import { OrderItemDetailsComponent } from './order-item-details';
import { ImageUploadComponent } from './image-upload';
import { DspCampaignsListComponent } from './dsp-campaigns-list';
import { ModalIntegrationsReportComponent } from './modal-integrations-report';
import { ModalIntegrationsStatusComponent } from './modal-integrations-status';
import { CheckboxGroupComponent } from './checkbox-group';
import { CheckboxGroupFormComponent } from './checkbox-group-form';
import { CsvReportComponent } from './csv-report';
import { TableTitleComponent } from './table-title';
import { BackButtonDirective } from './back_button/backDirective';
import { CalculateAudienceComponent } from './calculate-audience';
import { InputWithSelectComponent } from './input-with-select';
import { ModalStreamApproveConfirmationComponent } from './modal-stream-approve-confirmation';
import { AppDetailsComponent } from './app-details/app-details.component';
import { GoogleTrimAppNamePipe } from './app-details/google-trim-app-name.pipe';
import { MobileAppUrlsComponent } from './mobile-app-urls/mobile-app-urls.component';
import { WebsiteUrlComponent } from './website-url/website-url.component';
import { RateLimiterFormComponent } from './rate-limiter-form';
import { InputEditorComponent } from './inline-edit';
import { MaxFloorPriceFormComponent } from './max-floor-price-form';

const DECLARATIONS_EXPORTS = [
  AccountsFormComponent,
  ToastComponent,
  ToastItemComponent,
  UserInfoComponent,
  TooltipComponent,
  TooltipDirective,
  ModalDateRangeComponent,
  ModalConfirmComponent,
  DatePickerComponent,
  FormLabelComponent,
  CampaignsListComponent,
  FormLabelComponent,
  DetailFieldComponent,
  DetailFieldAreaComponent,
  FoldableListComponent,
  CompanyFieldComponent,
  ChartComponent,
  BarChartComponent,
  FileUploadComponent,
  OrderPaymentsComponent,
  LoaderComponent,
  LoaderMinimalComponent,
  AudioComponent,
  BackgroundComponent,
  AutopromoteAdsListComponent,
  OrderItemDetailsComponent,
  ImageUploadComponent,
  DspCampaignsListComponent,
  ModalIntegrationsReportComponent,
  ModalIntegrationsStatusComponent,
  CheckboxGroupComponent,
  CheckboxGroupFormComponent,
  CsvReportComponent,
  TableTitleComponent,
  BackButtonDirective,
  ModalIntegrationText,
  ModalCheckPixelComponent,
  CalculateAudienceComponent,
  InputWithSelectComponent,
  ModalStreamApproveConfirmationComponent,
  AppDetailsComponent,
  MobileAppUrlsComponent,
  WebsiteUrlComponent,
  GoogleTrimAppNamePipe,
  RateLimiterFormComponent,
  InputEditorComponent,
  MaxFloorPriceFormComponent,
];

const PROVIDERS = [BsModalService, LoaderManager];

@NgModule({
  declarations: [DECLARATIONS_EXPORTS],
  imports: [
    TagInputModule,
    MomentModule,
    BsDropdownModule.forRoot(),
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    TagModule,
    ChartsModule,
    PlayTimesInputModule,
    MatCardModule,
    MatIconModule,
  ],
  entryComponents: [
    ModalDateRangeComponent,
    ModalConfirmComponent,
    ModalIntegrationText,
    TooltipComponent,
    ModalIntegrationsReportComponent,
    ModalIntegrationsStatusComponent,
    ModalCheckPixelComponent,
    CalculateAudienceComponent,
  ],
  exports: [DECLARATIONS_EXPORTS, TagModule, PlayTimesInputModule],
})
export class ComponentsModule {
  static forRoot(): ModuleWithProviders<ComponentsModule> {
    return {
      ngModule: ComponentsModule,
      providers: PROVIDERS,
    };
  }
}
