import { Injectable } from '@angular/core';
import { MeteorObservable } from 'meteor-rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { DspConfig } from '../models/dsp-config';
import { FilterType, MANUAL_FILTERS } from '../../app/admin/demand-side-platform/consts';

@Injectable()
export class DspConfigService {
  constructor() {}

  public list(term: string, sort: Record<string, 1 | -1>): Observable<DspConfig[]> {
    return MeteorObservable.call('dsp.config.list', term, sort);
  }
  public get(name: string): Observable<DspConfig> {
    return MeteorObservable.call<DspConfig>('dsp.config.get', name);
  }
  public dicts() {
    return MeteorObservable.call('dsp.config.dicts');
  }

  public update(dspConfig: Partial<DspConfig>): Observable<DspConfig> {
    return MeteorObservable.call('dsp.config.update', dspConfig);
  }
  public changeStatus(name: string, activate: boolean): Observable<DspConfig> {
    return MeteorObservable.call('dsp.config.changeStatus', name, activate);
  }
  public searchDict(
    type: string,
    term: string,
    exclude: string[],
    parents: string[] = []
  ): Observable<{ value: string; label: string }[]> {
    return MeteorObservable.call('dsp.config.dict.search', type, term, exclude, parents);
  }
  public listDict(
    type: FilterType,
    items: string[],
    parents: string[] = []
  ): Observable<{ value: string; label: string }[]> {
    if (MANUAL_FILTERS.includes(type)) {
      return new BehaviorSubject(items.map((value) => ({ value, label: value })));
    }
    return MeteorObservable.call('dsp.config.dict.list', type, items, parents);
  }
}
