<div class="max-floor-price-form" [formGroup]="form" [class.has-error]="invalid">
    <div class="form-group" >
        <app-form-label
          label="{{ label }}"
          inputId="maxFloorPriceId"
          msg="Max Floor Price of request which can be sent to DSP"
        ></app-form-label>
    </div>
    <div class="form-group">
        <label>Amount</label>
        <input type="number" class="form-control" id="amount" formControlName="amount" />
      </div>
      <div class="form-group">
        <label>Currency</label>
        <select class="form-control" id="currencies" formControlName="currency">
          <option [value]="item.value" *ngFor="let item of options">{{ item.label }}</option>
        </select>
      </div>
  </div>
  