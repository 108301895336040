import { Pipe, PipeTransform } from '@angular/core';
import { MaxFloorPrice } from 'common/models';

@Pipe({
  name: 'maxFloorPriceFormat',
})
export class MaxFloorPricePipe implements PipeTransform {
  transform(maxFloorPrice: MaxFloorPrice, args?: any): any {
    return `${maxFloorPrice.amount} ${maxFloorPrice.currency.toUpperCase()}`;
  }
}
