import { RateLimit } from 'common/models';

export enum DspStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export type ListReference = {
  $ref: string;
};

export type FilterAction = 'ACCEPT' | 'REJECT';

export interface Filter {
  match: {
    [name: string]: string[] | ListReference;
  };
  action: FilterAction;
}

export interface FilterList {}
export interface FilterLists {
  [name: string]: FilterList;
}

export interface Filtering {
  rules?: Rules;
  filters?: Filter[];
  lists?: FilterLists;
  defaultFiltersAction?: FilterAction;
}

export interface Rules {
  [name: string]: string[];
}

interface AdsTxtEntry {
  adExchange: string;
  publisherAccountID: string;
  relationshipType: 'RESELLER' | 'PUBLISHER';
  certificationAuthorityTAG: 'string';
}

export interface MaxFloorPrice {
  currency: 'USD' | 'GBP' | 'RON' | 'PLN' | 'EUR';
  amount: number;
}

export interface DspBidder {
  class: string; // FakeServiceGateway | UndiciServiceGateway;
  http: {
    class: string;
    url: string;
  };
  endpointSelectionStrategy:
    | 'DefaultSelectionStrategy'
    | 'ContinentBasedSelectionStrategy'
    | 'AudioProductTypeBasedSelectionStrategy';
  endpoints: {
    url: string;
    keys: string[];
    default?: boolean;
  }[];
  nativeCurrencies: string[];
}

export interface DspConfig {
  name: string;
  label: string;
  description: string;
  status: DspStatus;
  filtering: Filtering;
  adsTxtEntries: AdsTxtEntry[];
  rateLimit: RateLimit;
  tcfConsentId: number;
  createdAt: Date;
  updatedAt: Date;
  maxFloorPrice?: MaxFloorPrice;
  bidder: DspBidder;
}
