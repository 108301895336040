import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule as NgCommonModule } from '@angular/common';
import { MomentModule } from 'angular2-moment';
import { ModalModule } from 'ngx-bootstrap/modal';
import {
  ToastService,
  UserService,
  ModalService,
  CampaignsService,
  PublisherService,
  AdminService,
  PaymentService,
  AutopromoteAdsService,
  StreamAnalysisService,
  AudienceService,
  StreamService,
  CsvService,
  TrackingService,
  AdvertiserPixelsService,
  InvoiceService,
  RedeemRequestsService,
  FileService,
  CountryService,
  AppDataService,
  WebsiteDataService,
  AdCategoriesService,
} from './services';
import {
  ActiveAccountGuard,
  AdminGuard,
  AdvertiserGuard,
  AuthorizedGuard,
  PublisherGuard,
  RegisterGuard,
  UnauthorizedGuard,
  FilledGuard,
  AcquirerGuard,
} from './guards';
import {
  CapitalizePipe,
  Price2Pipe,
  Price6Pipe,
  SortByValue,
  AbsolutePipe,
  ShortNumberPipe,
  DatePipe,
  TruncatePipe,
  EmptyPipe,
  InventoryRelationshipPipe,
  RateLimitFormatPipe,
} from './pipes';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxPaginationModule } from 'ngx-pagination';
import { DspConfigService } from './services/dsp-config.service';
import { MobileSdkService } from './services/mobile-sdk.service';
import { PublisherExcellenceService } from './services/publisher-excellence.service';
import { SupplyPackagesService } from './services/supply-packages.service';
import { MaxFloorPricePipe } from './pipes/max-floor-price.pipe';

const PROVIDERS = [
  ToastService,
  UserService,
  ModalService,
  CampaignsService,
  AdCategoriesService,
  AudienceService,
  ActiveAccountGuard,
  AdminGuard,
  AdvertiserGuard,
  AuthorizedGuard,
  PublisherGuard,
  AcquirerGuard,
  RegisterGuard,
  UnauthorizedGuard,
  FilledGuard,
  PublisherService,
  AdminService,
  PaymentService,
  AutopromoteAdsService,
  StreamAnalysisService,
  StreamService,
  CsvService,
  TrackingService,
  AdvertiserPixelsService,
  InvoiceService,
  RedeemRequestsService,
  FileService,
  CountryService,
  AppDataService,
  WebsiteDataService,
  DspConfigService,
  MobileSdkService,
  PublisherExcellenceService,
  SupplyPackagesService,
];

@NgModule({
  declarations: [
    AbsolutePipe,
    CapitalizePipe,
    DatePipe,
    Price2Pipe,
    Price6Pipe,
    TruncatePipe,
    SortByValue,
    ShortNumberPipe,
    EmptyPipe,
    InventoryRelationshipPipe,
    RateLimitFormatPipe,
    MaxFloorPricePipe,
  ],
  imports: [NgCommonModule, MomentModule, ModalModule, NgxPaginationModule, UiSwitchModule],
  exports: [
    NgCommonModule,
    AbsolutePipe,
    CapitalizePipe,
    DatePipe,
    ShortNumberPipe,
    Price2Pipe,
    Price6Pipe,
    TruncatePipe,
    MomentModule,
    ModalModule,
    NgxPaginationModule,
    UiSwitchModule,
    SortByValue,
    EmptyPipe,
    InventoryRelationshipPipe,
    RateLimitFormatPipe,
    MaxFloorPricePipe,
  ],
})
export class CommonModule {
  static forRoot(): ModuleWithProviders<CommonModule> {
    return {
      ngModule: CommonModule,
      providers: PROVIDERS,
    };
  }
}
